// Font Family
$nunitosans: 'Nunito Sans', sans-serif;
$roboto: 'Roboto', sans-serif;

// Colors ---------------
$white: #ffffff;
$theme-color: #80011f;
$theme-dark-color: #FFA600;
$theme-yellow: #2b2b2c;
$theme-yellow-dark: #1444a2;
$theme-blue: #000000;
$theme-blue-dark: #000000;
$body-text-color: #000000;
$body-text-color-grey: #000000;
$body-text-color-grey3: #000000;
$grey-bg: #ecf3f0;
$grey-bg-light: #f7f7f7;
$grey-bg-lighttwo: #f5f5f5;


// Heading Color
$heading-color: #FFA600;
$heading-color-black: #1444a2;

.kJVksy{
    background: $theme-color;
  }
  

// Soft color


// Responsive Variables
$xxxl: 'only screen and (min-width: 1600px) and (max-width: 1750px)';
$xxl: 'only screen and (min-width: 1400px) and (max-width: 1599px)';
$xl: 'only screen and (min-width: 1200px) and (max-width: 1399px)';
$lg : 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md:'only screen and (min-width: 768px) and (max-width: 991px)';
$xs:'(max-width: 767px)';
$sm: 'only screen and (min-width: 576px) and (max-width: 767px)';
