/*----------------------------------------*/
/*  01. Theme default
/*----------------------------------------*/

/* 1. Theme default css */
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800;900&family=Roboto:wght@300;400;500;700;900&display=swap');
 body {
	font-family: $roboto;
	font-weight: 400;
    font-style: normal;
    color:$body-text-color;
    font-size: 16px;
}
.tp-custom-container {
    max-width: 1630px;
    min-width: 1630px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
    @media #{$xxxl} {
        max-width: 1570px;
        min-width: 1570px;
    }
    @media #{$xxl,$xl,$lg,$md,$xs} {
        max-width: 100%;
        min-width: 100%;
    }
}
.img,img {
	max-width: 100%;
	transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
}
.f-left {
	float: left
}
.f-right {
	float: right
}
.fix {
	overflow: hidden
}
a,
button,
i {
	@include transition(.3s);
    text-decoration: none;
}
a:focus,
.button:focus {
	text-decoration: none;
	outline: none;
}
a:focus,
a:hover
{
	color: inherit;
	text-decoration: none;
}
a,
button {
	color: inherit;
	outline: medium none;
    text-decoration: none;
}
button:hover{
    color: inherit;
}
.nav-link:focus, .nav-link:hover {
    color: inherit;
}
button:focus,input:focus,input:focus,textarea,textarea:focus{outline: 0}
.uppercase {
	text-transform: uppercase;
}
.capitalize {
	text-transform: capitalize;
}
input,
select,
textarea {
    font-family: $roboto;
    font-weight: 400;
    opacity: 1;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $nunitosans;
	color: $heading-color;
	margin: 0px;
	font-style: normal;
	font-weight: 800;
	text-transform: normal;
    margin-bottom: 10px;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: inherit;
}
ul {
	margin: 0px;
	padding: 0px;
}
p {
	font-size: 16px;
	font-weight: 400;
	line-height: 27px;
	color: $body-text-color;
	margin-bottom: 10px;
}
i {
    line-height: 1;
    display: inline-block;
}
label {
	color: $body-text-color;
	cursor: pointer;
	font-size: 14px;
	font-weight: 400;
}
.display-inline {
    display: inline-block;
}
// *::-moz-selection {
// 	background: $black;
// 	color: $white;
// 	text-shadow: none;
// }
// ::-moz-selection {
// 	background: $black;
// 	color:$white;
// 	text-shadow: none;
// }
// ::selection {
// 	background: $black;
// 	color: $white;
// 	text-shadow: none;
// }
select {
    -webkit-appearance: none;
    -moz-appearance: none;
}
input,
select {
   font-family: $roboto;
    font-weight: 400;
}
.custom-pad-20 {
    padding-left: 10px;
    padding-right: 10px;
}
.custom-mar-20 {
    margin-right: -10px;
    margin-left: -10px;
}
.radius-50 {
    border-radius: 50px;
}
/*--
    - Input Placeholder
-----------------------------------------*/
*input::-moz-placeholder {
	font-size: 16px;
	opacity: 1;
    font-family: $roboto;
    font-weight: 400;
}
*input::placeholder {
	font-size: 16px;
	opacity: 1;
    font-family: $roboto;
    font-weight: 400;
}


/*--
    - Common Classes
-----------------------------------------*/
.clear{
    clear: both;
}

.translate-y--10 {
    transform: translateY(-10px);
}
/*--
    - Background color
-----------------------------------------*/
.theme-bg {
    background: $theme-color;
}
.theme-yellow-bg {
    background: $theme-yellow;
}
.white-bg {
    background: $white;
}
.yellow-dark-bg {
    background: $theme-yellow-dark;
}
.blue-dark-bg {
    background: $theme-blue-dark;
}
.theme-dark-bg {
    background: $theme-dark-color;
}
.theme-dark-bg2 {
    background: #041459;
}
.theme-dark-bg3 {
    background: #18255e;
}
.grey-bg {
    background: $grey-bg;
}
.bg-green-light {
    background: #084d2b;
}
.bg-gray-light {
    background: $grey-bg-light;
}
/*--
    - color
-----------------------------------------*/
.white-color {
	color: $white;
}
.theme-yelow-color {
	color: $theme-yellow;
}
.theme-blue-color {
	color: $theme-blue;
}
.theme-color {
	color: $theme-color;
}
.font-normal {
    font-weight: 400 !important;
}
.color-theme-blue {
    color: $theme-blue !important;
}
.heading-color-black {
    color: $heading-color-black !important;
}
.heading-color-black-with-hover {
    color: $heading-color-black !important;
    &:hover {
        color: $theme-color !important;
    }
}
/*--
    - Margin & Padding
-----------------------------------------*/
/*-- Margin Top --*/
@for $i from 1 through 40 {
    .mt-#{5 * $i}{margin-top: 5px * $i;}
}

/*-- Margin Bottom --*/
@for $i from 1 through 40 {
    .mb-#{5 * $i}{margin-bottom: 5px *$i;}
}
/*-- Margin Left --*/
@for $i from 1 through 40 {
    .ml-#{5 * $i}{margin-left: 5px * $i;}
}

/*-- Margin Right --*/
@for $i from 1 through 40 {
    .mr-#{5 * $i}{margin-right: 5px *$i;}
}

/*-- Padding Top --*/
@for $i from 1 through 40 {
    .pt-#{5 * $i}{padding-top: 5px *$i;}
}

/*-- Padding Bottom --*/
@for $i from 1 through 40 {
    .pb-#{5 * $i}{padding-bottom: 5px *$i;}
}

/*-- Padding Left --*/
@for $i from 1 through 40 {
    .pl-#{5 * $i}{padding-left: 5px *$i;}
}

/*-- Padding Right --*/
@for $i from 1 through 40 {
    .pr-#{5 * $i}{padding-right: 5px *$i;}
}


// others common css here :)


//section title style
.tp-section-subtitle {
    font-size: 16px;
    font-weight: 500;
}
.tp-section-title,
.tp-section-title-two {
    font-size: 55px;
    line-height: 1.18;
    @media #{$xl} {
        font-size: 48px;
    }
    @media #{$lg} {
        font-size: 40px;
    }
    @media #{$md} {
        font-size: 36px;
    }
    @media #{$xs} {
        font-size: 32px;
        & br {
            display: none;
        }
    }
    @media #{$sm} {
        & br {
            display: inline-block;
        }
    }
}
.tp-section-title-two {
    color: $heading-color-black;
    & span {
        color: $theme-color;
        &.theme-yellow {
            color: $theme-yellow;
        }
    }
}
.tp-section-subtitle-three {
    font-size: 18px;
    color: $theme-color;
    font-weight: 500ss;
}

//btn style

.yellow-btn {
    font-size: 16px;
    font-weight: 500;
    border: none;
    outline: none;
    box-shadow: none;
    height: 60px;
    line-height: 60px;
    color: white;
    background: $theme-yellow;
    text-align: center;
    padding: 0px 38px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: inline-block;
    &:hover {
        background: $theme-color;
        color: $white;
    }
}
.theme-btn {
    font-size: 16px;
    display: inline-block;
    font-weight: 500;
    border: none;
    outline: none;
    box-shadow: none;
    height: 60px;
    line-height: 60px;
    color: $white;
    background: $theme-color;
    text-align: center;
    padding: 0px 38px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:hover {
        background: $theme-yellow;
        color: $body-text-color;
    }
}
.yellow-btn,
.theme-btn {
    &:before {
        background-color: #fff;
        content: "";
        height: 150px;
        left: -75px;
        position: absolute;
        top: -35px;
        transform: rotate(35deg);
        transition: all 1600ms cubic-bezier(.19,1,.22,1);
        width: 40px;
        opacity: 0;
    }
    &:hover {
        &:before {
            left: 120%;
            transition: all 1300ms cubic-bezier(.19,1,.22,1);
            opacity: .25;
        }
    }
    & i {
        vertical-align: middle;
        display: inline-block;
        margin-right: 2px;
        line-height: 1;
    }
}
.hover-theme-color:hover {
    color: $theme-color;
}
.progress-wrap {
    @media #{$xs} {
        right: 30px;
    }
}
//custom z-index
.z-index {
    z-index: 2;
    position: relative;
}

//custom swipper dots
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    width: inherit;
}
.swiper-container-horizontal.common-dots {
    position: relative;
    & .slide-dots {
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
    }
}
.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    display: inline-block;
    margin: 0px 5px;
    padding: 0px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 3px solid #c6c9cb;
    display: inline-block;
    outline: none;
    cursor: pointer;
    background: none;
    font-size: 0px;
    opacity: 1;
    &.swiper-pagination-bullet-active {
        border-color: $theme-color;
    }
}


.swiper-container .swiper-pagination-bullets {
    position: absolute ;
    top: 50% ;
    transform: translateY(-50%) ;
    left: 80px ;
    z-index: 5 ;
    width: 20px ;
    bottom: auto ;
}

.swiper-container .swiper-pagination-bullet.swiper-pagination-bullet-active {
    height: 6px ;
    width: 6px ;
    background: #06ae5a ;
    margin: 11px ;
    margin-left: 9px ;
    transform: translateY(2px) ;
    position: relative;
    margin: 4px 8px;
}

.swiper-container .swiper-pagination-bullet {
    font-size: 0px ;
    height: 12px ;
    width: 12px ;
    padding: 3px ;
    background: #97bdaa ;
    cursor: pointer ;
    border-radius: 50% ;
    border: none ;
    display: inline-block ;
    outline: none ;
    position: relative ;
    margin: 6px ;
    opacity: 1 ;
}

.swiper-container .swiper-pagination-bullet.swiper-pagination-bullet-active::before {
    content: '';
    position: absolute;
    border-color: #06ae5a ;
}

.swiper-container .swiper-pagination-bullet::before {
    position: absolute ;
    content: "" ;
    height: 18px ;
    width: 18px ;
    border-radius: 50% ;
    border: 2px solid transparent ;
    top: -6px ;
    left: -6px ;
}

@media #{$xs} {
    .swiper-slide {
    padding: 10px;
}
}
.tp-slider-video-btn button {
    height: 400px;
    line-height: 90px;
    width: 400px;
    background: #06ae5a;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    font-size: 16px;
    color: #ffffff;
    position: relative;
    z-index: 2;
    border: none;
}
.tp-slider-video-btn-two button {
    height: 80px;
    line-height: 80px;
    width: 80px;
    background: #06ae5a;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    font-size: 16px;
    color: #ffffff;
    position: relative;
    z-index: 2;
    border: none;
}



